.App {
    text-align: center;
  }
  
  .scoreboard {
    margin: 20px;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 20px;
  }
  
  .card {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .card img {
    width: 100%;
    height: auto;
  }
  
  .card p {
    margin: 10px 0;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  